.hypnotic {
   width: 56px;
   height: 56px;
   border: 2.2px solid #474bff;
   border-radius: 50%;
   display: grid;
   animation: hypnotic-ss6pk4 1.5s infinite linear;
   transform-origin: 50% 80%;
}

.hypnotic:before,
.hypnotic:after {
   content: "";
   grid-area: 1/1;
   border: inherit;
   border-radius: 50%;
   animation: inherit;
   animation-duration: 1s;
   transform-origin: inherit;
}

.hypnotic:after {
   --s: -1;
}

@keyframes hypnotic-ss6pk4 {
   100% {
      transform: rotate(calc(var(--s,1)*1turn));
   }
}

.spinner {
   width: 11.2px;
   height: 11.2px;
   margin-bottom: 20px;
   animation: spinner-z355kx 1.5s infinite linear;
   border-radius: 11.2px;
   box-shadow: 28px 0px 0 0 #c2c3e8, 17.4px 21.8px 0 0 #c2c3e8, -6.2px 27.2px 0 0 #c2c3e8, -25.2px 12px 0 0 #c2c3e8, -25.2px -12px 0 0 #c2c3e8, -6.2px -27.2px 0 0 #c2c3e8, 17.4px -21.8px 0 0 #c2c3e8;
}

@keyframes spinner-z355kx {
   to {
      transform: rotate(360deg);
   }
}

.dots {
   width: 24px;
   height: 13.4px;
   background: radial-gradient(farthest-side,#b4b5d9 90%,#0000) 50%  0,
          radial-gradient(farthest-side,#b4b5d9 90%,#0000) 100% 0;
   background-size: 5.8px 5.8px;
   background-repeat: no-repeat;
   position: relative;
   animation: dots-pr30iymn 1.5s linear infinite;
}

.dots:before {
   content: "";
   position: absolute;
   width: 5.8px;
   height: 5.8px;
   border-radius: 50%;
   background: #b4b5d9;
   left: 0;
   top: 0;
   animation: dots-q6bpf4mn 1.5s linear infinite,
          dots-b39z9imn 0.5s cubic-bezier(0,200,.8,200) infinite;
}

@keyframes dots-pr30iymn {
   0%, 31% {
      background-position: 50% 0   ,100% 0;
   }

   33% {
      background-position: 50% 100%,100% 0;
   }

   43%, 64% {
      background-position: 50% 0   ,100% 0;
   }

   66% {
      background-position: 50% 0   ,100% 100%;
   }

   79% {
      background-position: 50% 0   ,100% 0;
   }

   100% {
      transform: translateX(calc(-100%/3));
   }
}

@keyframes dots-q6bpf4mn {
   100% {
      left: calc(100% + 3.4px);
   }
}

@keyframes dots-b39z9imn {
   100% {
      top: -0.07px;
   }
}