a {
  color: inherit;
  text-decoration: none;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: rgba(0, 0, 0, 0.23)!important;
  border-style: solid;
  border-width: 1px;
}

/* MODAL */

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #3A4990;
  padding: 20px;
  border-radius: 10px;
  border: '2px solid #000';
  box-shadow: 24;
}